import React, {useEffect, useState} from "react";
import carLeftSvg from "../../assets/car-arrow-left.svg";
import carRightSvg from "../../assets/car-arrow-right.svg";
import handSvg from "../../assets/icons/svg/hand.svg";
import {getAvailableColors, getMountingRimByType} from "../api/configurator.api";
import {IAvailableColors, IRimConfig, ISearchRequestParams} from "../interfaces/rim-config.interface";
import {BmfCarAngle} from "./bmf-car-angle.component";
import ColorPaletteComponent from "./color-palette.component";
import {RimImageLoading} from "./rim-image-loading.component";
import {useMediaQuery} from "../../helper/useMediaQuery";

type Props = ISearchRequestParams & {
    imageLoaded: (data: IRimConfig) => void,
    boxView?: boolean,
    carViewId?: string,
    boxViewHeightHandler?: Function;
    landScapeBarExpanded?: boolean;
    fullScreenToggled?: boolean;
}

let anglesLoaded = new Set<string>()
export function RimConfigComponent(props: Props) {
    const [allAnglesLoaded, setAllAnglesLoaded] = useState<boolean>(false);
    const [rimConfigData, setRimConfigData] = useState<IRimConfig | undefined>(
        undefined
    );
    const [availableColors, setAvailableColors] = useState<IAvailableColors[]>(
        []
    );
    const [imageIndex, setImageIndex] = useState<number>(0);
    const [scale, setScale] = useState<number>(1);
    const [carColor, setCarColor] = useState<string | null>(null);
    const [maxImgSize, setMaxImgSize] = useState<{ width: number, height: number }>({width: 1000, height: 500});
    const [startPosition, setStartPosition] = useState<any>();
    const isSmallMobile = useMediaQuery("(max-width: 700px)");
    const minHeight = scale * maxImgSize.height;
    const minWidth = scale * maxImgSize.width;


    const getConfig = async () => {
        try {
            const rimJob = await getMountingRimByType(props);
            let width = 0;
            let height = 0;
            rimJob.layers.forEach(l => {
                l.dimensions.forEach(d => {
                    if (d.width > width) {
                        width = d.width
                    }
                    if (d.height > height) {
                        height = d.height
                    }
                })
            })
            setMaxImgSize({width, height});
            if (rimJob.numberOfImagesAngles < imageIndex + 1) {
                setImageIndex(0)
            }
            props.imageLoaded(rimJob);
            return setRimConfigData(rimJob);
        } catch (error) {
            console.log(error);
        }
    };

    const getColors = async () => {
        try {
            const allColors = await getAvailableColors(props.vehicleId);
            setAvailableColors(allColors);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const mobileScale = isSmallMobile ? 0.6 : 0.7;
        setScale(props.boxView ? 0.1 : props.landScapeBarExpanded ? 0.35 : mobileScale);
        anglesLoaded =  new Set();
    }, []);

    useEffect(() => {
        if (!props.hideColorSelect) {
            getColors();
        }
        getConfig();
    }, [props.rimId, props.vehicleId, props.use3d]);

    useEffect(() => {
        if (props.boxView && props.boxViewHeightHandler) {
            props.boxViewHeightHandler(scale * maxImgSize.height)
        }
    }, [scale, maxImgSize]);

    useEffect(() => {
        const mobileScale = isSmallMobile ? 0.6 : 0.7;
        setScale(props.boxView ? 0.1 : props.landScapeBarExpanded ? 0.35 : mobileScale);
    }, [props.landScapeBarExpanded, props.fullScreenToggled]);


    function handleResize(evt?: UIEvent) {
        let widthScale = 1;
        let heightScale = 1;

        const carView = props.carViewId
            ? document.getElementById(props.carViewId)
            : document.getElementById('car-view');


        if (props.boxView) {
            if (carView.clientWidth < maxImgSize.width) {
                widthScale = ((100 / maxImgSize.width) * carView.clientWidth) / 100;
            }
        } else {
            if (window.innerWidth < 1200) {
                widthScale = ((100 / 1200) * window.innerWidth) / 100;
            }

            if (window.innerHeight - carView.offsetTop - 200 < maxImgSize.height) {
                heightScale = (100 / maxImgSize.height) * (window.innerHeight - carView.offsetTop - (200)) / 100
            }
        }

        setScale(Math.max(Math.min(widthScale, heightScale), props.boxView ? 0.1 : props.landScapeBarExpanded ? 0.35 : 0.4));

        if (!props.boxView && (!props.landScapeBarExpanded && props.fullScreenToggled)) {
            heightScale = (100 / maxImgSize.height) * (window.innerHeight - carView.offsetTop - 50) / 100
            const mobileScale = 0.35;
            setScale(Math.max(Math.min(widthScale, heightScale), mobileScale));
        }
    }

    useEffect(() => {
        if (window) {
            window.addEventListener("resize", handleResize);
            handleResize();
            return () => window.removeEventListener("resize", handleResize);
        }
    }, [maxImgSize]);

    function onRotateImageRight() {
        if (imageIndex === rimConfigData!.numberOfImagesAngles! - 1) {
            setImageIndex(
                imageIndex - (rimConfigData!.numberOfImagesAngles! - 1)
            );
        }
        if (imageIndex !== rimConfigData!.numberOfImagesAngles! - 1) {
            setImageIndex(imageIndex + 1);
        }
    }

    function onRotateImageLeft() {
        if (imageIndex === 0) {
            setImageIndex(rimConfigData!.numberOfImagesAngles! - 1);
        }
        if (imageIndex !== 0) {
            setImageIndex(imageIndex - 1);
        }
    }

    function getRequiredAngles() {
        const imgsBeforeAndAfter = props.use3d ? rimConfigData.numberOfImagesAngles : 2;
        const angleElements = [];
        if (props.boxView) {
            angleElements.push(
                <BmfCarAngle
                    key={0 + '_' + rimConfigData?.rimId}
                    layers={rimConfigData?.layers}
                    angleIndex={0}
                    carColor={
                        props.hideColorSelect ? props.carColor : carColor
                    }
                    currentImage={imageIndex}
                    scale={scale}
                    lowering={props.lowering}
                    angleLoaded={(angleIndex) => {
                        anglesLoaded.delete(angleIndex + '');
                        if(anglesLoaded.size === 0) {
                            setAllAnglesLoaded(true)
                        }
                    }}
                    angleLoading={(angleIndex) => {
                        anglesLoaded.add(angleIndex + '');
                        if(anglesLoaded.size === 1) {
                            setAllAnglesLoaded(false)
                        }
                    }}
                    rimLoadingProps={{minWidth, minHeight}}
                />);
        } else {
            for (let i = 0; i < rimConfigData.numberOfImagesAngles; i++) {
                if (
                    (i <= imageIndex + imgsBeforeAndAfter &&
                        i >= imageIndex - imgsBeforeAndAfter) ||
                    (i >=
                        rimConfigData.numberOfImagesAngles +
                        (imageIndex - imgsBeforeAndAfter)) ||
                    (i <= (imageIndex + imgsBeforeAndAfter) - rimConfigData.numberOfImagesAngles)
                ) {
                    angleElements.push(
                        <BmfCarAngle
                            key={i + '_' + rimConfigData?.rimId}
                            layers={rimConfigData?.layers}
                            angleIndex={props.boxView ? 0 : i}
                            carColor={
                                props.hideColorSelect ? props.carColor : carColor
                            }
                            currentImage={imageIndex}
                            scale={scale}
                            lowering={props.lowering}
                            angleLoaded={(angleIndex) => {
                                anglesLoaded.delete(angleIndex + '');
                                if(anglesLoaded.size === 0) {
                                    setAllAnglesLoaded(true)
                                }
                            }}
                            angleLoading={(angleIndex) => {
                                anglesLoaded.add(angleIndex + '');
                                if(anglesLoaded.size === 1) {
                                    setAllAnglesLoaded(false)
                                }
                            }}
                            rimLoadingProps={{minWidth, minHeight}}
                        />
                    );
                }
            }
        }
        return angleElements;
    }

    function dragStart($event) {
        let currentX = 0;
        if (Object.hasOwn($event, 'pageX')) {
            currentX = ($event as MouseEvent).pageX
        } else if (Object.hasOwn($event, 'touches')) {
            currentX = ($event as TouchEvent).touches?.[0]?.clientX
        }
        setStartPosition(currentX);
    }

    function dragging($event) {
        if (startPosition !== undefined) {
            let currentX = 0;
            if (Object.hasOwn($event, 'pageX')) {
                currentX = ($event as MouseEvent).pageX
            } else if (Object.hasOwn($event, 'touches')) {
                currentX = ($event as TouchEvent).touches?.[0]?.clientX
            }
            if (currentX > (startPosition + 30)) {
                onRotateImageRight();
                setStartPosition(currentX);
            } else if (currentX < (startPosition - 30)) {
                onRotateImageLeft();
                setStartPosition(currentX);
            }
        }
    }

    function dragEnd($event) {
        setStartPosition(undefined);
    }

    if (rimConfigData) {
        const showLoading = props.use3d ? allAnglesLoaded : false;
        return !props.boxView ? (
                <div className="container-xl">
                    {showLoading && <RimImageLoading
                        minHeight={minHeight}
                        minWidth={minWidth}
                    />}
                    <div style={{display: !showLoading ? "block" : "none"}} className="row">
                        <div className="col-12">
                            <div className="rim-config">
                                {props.use3d && rimConfigData?.numberOfImagesAngles > 1 &&
                                    <div className={`drag-overlay ${startPosition !== undefined ? 'dragging' : ''}`}
                                         onTouchStart={dragStart} onTouchEnd={dragEnd} onTouchMove={dragging}
                                         onMouseDown={dragStart} onMouseUp={dragEnd} onMouseOut={dragEnd}
                                         onMouseMove={dragging}></div>
                                }

                                <button
                                    className={
                                        rimConfigData?.numberOfImagesAngles === 1
                                            ? "btn-custom previous align-content-lg-end d-none"
                                            : "btn-custom previous align-content-lg-end"
                                    }
                                    onClick={onRotateImageLeft}
                                    disabled={rimConfigData?.numberOfImagesAngles === 1}
                                    style={{bottom: scale * 50}}
                                >
                                    <img width={scale * 160} src={carLeftSvg}/>
                                </button>
                                <div
                                    className="rim-image-layers mt-md-3"
                                    style={{
                                        minHeight,
                                        minWidth,
                                    }}
                                >
                                    {getRequiredAngles()}
                                </div>
                                <button
                                    onClick={onRotateImageRight}
                                    className={
                                        rimConfigData?.numberOfImagesAngles === 1
                                            ? "btn-custom next align-content-lg-end d-none"
                                            : "btn-custom next align-content-lg-end"
                                    }
                                    style={{bottom: scale * 50}}
                                >
                                    <img width={scale * 160} src={carRightSvg}/>
                                </button>
                            </div>
                            {!props.hideColorSelect && (
                                <div className="rim-config">
                                    <div className="rim-config-title">Fahrzeugfarbe:</div>
                                    <div className="rim-ui-layer">
                                        <div className="d-inline-flex">
                                            <ColorPaletteComponent
                                                availableColors={availableColors}
                                                setColor={(color) => setCarColor(color)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )
            : (
                <>{getRequiredAngles()}</>
            );
    } else {
        return null;
    }
}
